import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

import { DialogTheme } from './Dialog';

export const styles = ({ spacing, breakpoints }: Theme) => {
  return createStyles({
    rootContainer: {},
    root: {
      position: 'absolute',
      top: 30,
      width: 500,

      [breakpoints.down('xs')]: {
        minWidth: 'auto',
        width: '100%',
      },
    },

    titleRoot: {
      borderBottom: `1px solid ${colors.grey.lightest}`,
      padding: 0,
    },
    title: {
      gap: 8,
      padding: spacing(5, 4, 4),
    },
    heading: {
      fontSize: 20,
      fontWeight: 600,
    },
    headingActions: {
      gap: 8,
    },
    closeIcon: {
      color: colors.grey.main,
      padding: 0,
    },
    closeIconIcon: {},
    body: {},
    actions: {
      alignItems: 'center',
      background: colors.white.lightest,
      borderTop: `1px solid ${colors.grey.lightest}`,
      flexDirection: 'column',
      padding: spacing(5, 7, 6),
    },
    actionButtons: {
      width: '100% !important',
    },
    actionsError: {
      color: colors.red.darkish,
      margin: spacing(0, 0, 2, 2),
    },
    actionsButtons: {
      width: '100%',
    },
    cancel: {
      marginRight: spacing(2),
    },

    // Themes
    [DialogTheme.legacy]: {},
    [DialogTheme.new]: {
      borderRadius: 12,

      '& $titleRoot': {
        borderBottom: '1px solid #EAECF0', // TODO replace after creating new palette
      },
      '& $title': {
        padding: spacing(4, 6),
      },
      '& $heading': {
        color: '#101828', // TODO replace after creating new palette
        fontSize: 18,
        fontWeight: 600,
      },
      '& $closeIcon': {
        color: '#7E8899', // TODO replace after creating new palette
        borderRadius: 6,
        padding: spacing(2),
      },
      '& $closeIconIcon': {
        fontSize: 16,
      },
      '& $body': {
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(6),
        padding: spacing(6),
      },
      '& $actions': {
        borderTop: '1px solid #EAECF0', // TODO replace after creating new palette
        padding: spacing(4, 6),
      },
      '& $actionButtons': {
        gap: spacing(2),
        justifyContent: 'flex-end',
      },
    },
  });
};
